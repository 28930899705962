import React from "react"
import SEO from "../../components/seo"
import Header from "../../components/header"

import WorkFooter from "../../components/portfolioItems/worksFooter"


import Herospace from "../../../assets/images/portfolio_items/nuyu/nuyu.png"
import nuyu1 from "../../../assets/images/portfolio_items/nuyu/nuyu1.png"
import nuyu2 from "../../../assets/images/portfolio_items/nuyu/nuyu2.png"
import nuyu3 from "../../../assets/images/portfolio_items/nuyu/nuyu3.png"
import nuyu4 from "../../../assets/images/portfolio_items/nuyu/nuyu4.png"
import nuyu5 from "../../../assets/images/portfolio_items/nuyu/nuyu5.png"
import nuyu6 from "../../../assets/images/portfolio_items/nuyu/nuyu6.png"

export default () => 
<div>
    <SEO title={'ICON Worldwide | NuYu - Wordpress Website Design'} 
    description="A WordPress Website Design that rocks: discover now what ICON did for NuYu, the first health club for women in Saudi Arabia!"
    canonical={'https://icon-worldwide.com/works/nuyu'} />
        <Header bgcolor="#292929"/>
    <div id="portfolio-item">
        <div className="herospace">
            <img src={Herospace} alt="NuYu, ICON Worldwide, WordPress website design, portfolio" title="ICON Worldwide portfolio, WordPress website design, NuYu"/>
            <div className="title"><h1>NuYu<span></span></h1>
            <h2>A WordPress website design that rocks</h2>
            </div>
        </div>
        <div className="portfolio-inner">
        <p>NuYu is the first health club for women in Saudi Arabia. ICON created the WordPress website including all graphic design elements.</p>
        <div className="portfolio-images">
            <img src={nuyu1} alt="NuYu, ICON Worldwide, WordPress website design, portfolio" title="ICON Worldwide portfolio, WordPress website design, NuYu"/>
            <div></div>
            <img src={nuyu2} alt="NuYu, ICON Worldwide, WordPress website design, portfolio" title="ICON Worldwide portfolio, WordPress website design, NuYu"/>
        </div>
        <img className="full-img desktop-img" src={nuyu3} alt="NuYu, ICON Worldwide, WordPress website design, portfolio" title="ICON Worldwide portfolio, WordPress website design, NuYu"/>
        <div className="portfolio-images">
            <img className="mobile-img" src={nuyu6} alt="NuYu, ICON Worldwide, WordPress website design, portfolio" title="ICON Worldwide portfolio, WordPress website design, NuYu"/>
            <img src={nuyu4} alt="NuYu, ICON Worldwide, WordPress website design, portfolio" title="ICON Worldwide portfolio, WordPress website design, NuYu"/>
            <div></div>
            <img src={nuyu5} alt="NuYu, ICON Worldwide, WordPress website design, portfolio" title="ICON Worldwide portfolio, WordPress website design, NuYu"/>
        </div>
        <WorkFooter previous="flavorwiki" next="credit-suisse"/>
        </div>
    </div>
</div>